import { useEffect } from 'react';
import { CLIP_VIDEO_ID } from '../../clip-video/constants';
import { constants } from '../../../utils/constants';
import useClipsHandlers from './use-clips-handlers';

const { DEVICE_TYPE } = constants;

export const useClipsEffects = ({
  deviceType,
  clipsConfig,
  videoClipElRef,
  multimediaClipRef,
  isFullscreenRef,
  playerRef,
  snap,
  isCurrentClips,
  isFullscreen,
  isPreviousClips,
  onPause,
  position,
  mouseHoverConfigRef,
  eventHandlersRef,
  sendIntentionToViewTrack,
  multimediaWatched,
  setMultimediaWatched,
}) => {
  const isDesktop = deviceType === DEVICE_TYPE.DESKTOP;

  const { handleCurrentClipsFirstCase, handlePreviousClips, handleCurrentClipsSecondCase } = useClipsHandlers({
    isDesktop,
    deviceType,
    clipsConfig,
    videoClipElRef,
    multimediaClipRef,
    playerRef,
    snap,
    isFullscreen,
    onPause,
    position,
    mouseHoverConfigRef,
    eventHandlersRef,
    sendIntentionToViewTrack,
    multimediaWatched,
    setMultimediaWatched,
  });

  useEffect(() => {
    if (clipsConfig?.hasClips) {
      videoClipElRef.current = document.getElementById(CLIP_VIDEO_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCurrentClips) {
      handleCurrentClipsFirstCase();
    }

    /* istanbul ignore next */
    if (isPreviousClips) {
      handlePreviousClips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.current, position.currentClip]);

  useEffect(() => {
    isFullscreenRef.current = isFullscreen;

    if (isCurrentClips) {
      handleCurrentClipsSecondCase();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);
};
