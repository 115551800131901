const CLIPS_PROVIDERS = {
  VIDEOJS: 'videojs',
};
const SNACKBARS_ID = { OWN_ERROR: 'OWN_ERROR', CONNECTION_ERROR: 'CONNECTION_ERROR' };
const CLIPS_VIDEO_PROVIDER = CLIPS_PROVIDERS.VIDEOJS; // To configure the current video provider
const CLIP_VIDEO_ID = 'main-video';
const CLIP_PRELOAD_VIDEO_ID = 'clip-preload-video';
const CLIPS_EVENTS = {
  ERROR: { functionName: 'onError', eventName: 'error' },
  PAUSE: { functionName: 'onPause', eventName: 'pause' },
  PLAY: { functionName: 'onPlay', eventName: 'play' },
  PLAYING: { functionName: 'onPlaying', eventName: 'playing' },
  TIMEUPDATE: { functionName: 'onTimeUpdate', eventName: 'timeupdate' },
  VOLUMECHANGE: { functionName: 'onVolumeChange', eventName: 'volumechange' },
  WAITING: { functionName: 'onWaiting', eventName: 'waiting' },
};

export { CLIP_PRELOAD_VIDEO_ID, CLIP_VIDEO_ID, CLIPS_EVENTS, CLIPS_PROVIDERS, CLIPS_VIDEO_PROVIDER, SNACKBARS_ID };
