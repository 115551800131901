import { GALLERY_MODE, ID_CONTAINER } from '../constants';
import { isItemClip } from './use-gallery';
import getMultimediaProps from '../events/get-multimedia-props';
import { trackPage, trackEvent } from '../../../lib/tracking';
import { CLIPS_VIDEO_PROVIDER } from '../../clip-video/constants';
import { isPlaying, mute, pause, play, reset, restart, unmute } from '../../clip-video/Provider';
import { showDesktopMediaElements, shoutOffHover, shoutOnHover } from '../../clip-multimedia/utils/multimedia-events';
import { setVideoAppend } from '../../clip-video/utils/video-append';
import { constants } from '../../../utils/constants';

const { DEVICE_TYPE } = constants;

const useClipsHandlers = ({
  isDesktop,
  deviceType,
  clipsConfig,
  videoClipElRef,
  multimediaClipRef,
  playerRef,
  snap,
  isFullscreen,
  onPause,
  position,
  mouseHoverConfigRef,
  eventHandlersRef,
  sendIntentionToViewTrack,
  multimediaWatched,
  setMultimediaWatched,
}) => {
  const handleCurrentClipsFirstCase = () => {
    const { current, previous } = snap;

    multimediaClipRef.current.error = false;
    multimediaClipRef.current.hasStarted = false;
    multimediaClipRef.current.loading = false;
    multimediaClipRef.current.paused = true;
    multimediaClipRef.current.timeToLeave = 0;

    let trackToSend = current?.track_vip || current?.track_pdp;
    trackToSend = {
      ...trackToSend,
      melidata_event: {
        ...trackToSend?.melidata_event,
        event_data: {
          ...trackToSend?.melidata_event.event_data,
          gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
        },
      },
    };

    const trackDispatch = trackToSend?.melidata_event?.type === 'event' ? trackEvent : trackPage;
    trackDispatch(trackToSend);

    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP: {
        sendIntentionToViewTrack();

        const videoContainer = {
          current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
          opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
        };
        const shoutOnHoverProps = {
          multimedia: multimediaClipRef.current,
          mouseHoverConfig: mouseHoverConfigRef.current,
          videoContainer,
          videoId: current.id,
        };

        setVideoAppend(videoClipElRef.current, `${videoContainer.current}-${current?.id}`);
        shoutOnHover(eventHandlersRef.current, shoutOnHoverProps);
        break;
      }

      case DEVICE_TYPE.MOBILE: {
        const clipContainer = isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN;

        setVideoAppend(videoClipElRef.current, `${clipContainer}-${current?.id}`);

        break;
      }

      default:
        break;
    }

    if (clipsConfig?.autoplay) {
      if (!isItemClip(previous)) {
        restart(playerRef.current, CLIPS_VIDEO_PROVIDER);
      }

      if (!isDesktop && isFullscreen && multimediaClipRef.current.muted) {
        unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
      }
    } else if (!isDesktop && !isItemClip(previous) && isFullscreen) {
      play(playerRef.current, CLIPS_VIDEO_PROVIDER);
    }
  };

  const handlePreviousClips = () => {
    const { previous, prevClip } = snap;
    const videoStateFormated = {
      is_muted:
        typeof multimediaClipRef.current.muted === 'undefined' ? 'not_started' : `${multimediaClipRef.current.muted}`,
      is_playing:
        typeof multimediaClipRef.current.paused === 'undefined'
          ? 'not_started'
          : `${!multimediaClipRef.current.paused}`,
    };
    const multimediaDataTrack = {
      time_to_leave: multimediaClipRef.current.timeToLeave,
      has_started_at_least_once: multimediaClipRef.current.hasStarted,
      ...(isDesktop && {
        is_muted:
          typeof multimediaClipRef.current.muted === 'undefined' ? 'not_started' : `${multimediaClipRef.current.muted}`,
        is_playing:
          typeof multimediaClipRef.current.paused === 'undefined'
            ? 'not_started'
            : `${!multimediaClipRef.current.paused}`,
      }),
      ...(!isDesktop && { ...videoStateFormated }),
    };
    let trackToSend = previous?.track_leave;

    trackToSend = {
      ...trackToSend,
      melidata_event: {
        ...trackToSend?.melidata_event,
        event_data: {
          ...trackToSend?.melidata_event.event_data,
          ...multimediaDataTrack,
          gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
        },
      },
    };

    trackEvent(trackToSend);

    // The events are not being triggered when withEvents=false in <ClipsVideo />
    reset(playerRef.current, CLIPS_VIDEO_PROVIDER, () => {
      multimediaClipRef.current.hasStarted = false;
      onPause(playerRef.current, { currentVideo: prevClip }); // Execute just the logic of the event
    });

    if (isFullscreen) {
      setVideoAppend(videoClipElRef.current, `${ID_CONTAINER.GALLERY_SCREEN}-${previous?.id}`);
    }

    if (isDesktop) {
      const shoutOffHoverProps = {
        mediaContainer: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
        mouseHoverConfig: mouseHoverConfigRef.current,
        videoId: prevClip.id,
      };
      shoutOffHover(eventHandlersRef.current, shoutOffHoverProps);

      const multimediaProps = getMultimediaProps(
        prevClip?.id,
        multimediaClipRef.current,
        isFullscreen,
        mouseHoverConfigRef.current,
      );
      showDesktopMediaElements(multimediaProps);
    }
  };

  const handleTrackToSend = () => {
    const { current } = snap;
    const trackToSend = {
      ...current?.track_intention_to_view,
      melidata_event: {
        ...(current?.track_intention_to_view?.melidata_event || {}),
        event_data: {
          ...current?.track_intention_to_view?.melidata_event.event_data,
          gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
        },
      },
    };

    trackEvent(trackToSend);
    setMultimediaWatched(watchedList => {
      watchedList[position.current] = true;
      return watchedList;
    });
  };

  const handleCurrentClipsSecondCase = () => {
    const { current } = snap;
    const clipContainer = isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN;

    setVideoAppend(videoClipElRef.current, `${clipContainer}-${current?.id}`);

    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP: {
        const videoContainer = {
          current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
          opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
        };
        const shoutOffHoverProps = {
          mediaContainer: videoContainer.opposite,
          mouseHoverConfig: mouseHoverConfigRef.current,
          videoId: current.id,
        };
        const shoutOnHoverProps = {
          mouseHoverConfig: mouseHoverConfigRef.current,
          multimedia: multimediaClipRef.current,
          videoContainer,
          videoId: current.id,
        };

        shoutOffHover(eventHandlersRef.current, shoutOffHoverProps);
        shoutOnHover(eventHandlersRef.current, shoutOnHoverProps);

        break;
      }

      case DEVICE_TYPE.MOBILE: {
        const paused = !isPlaying(playerRef.current, CLIPS_VIDEO_PROVIDER);
        if (clipsConfig?.autoplay && isFullscreen) {
          unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
        }

        if (clipsConfig?.autoplay && !isFullscreen) {
          mute(playerRef.current, CLIPS_VIDEO_PROVIDER);

          if (paused) {
            play(playerRef.current, CLIPS_VIDEO_PROVIDER);
          }
        }

        if (!clipsConfig?.autoplay && !isFullscreen && !paused) {
          pause(playerRef.current, CLIPS_VIDEO_PROVIDER);
        } else if (isFullscreen) {
          play(playerRef.current, CLIPS_VIDEO_PROVIDER);
        }

        if (isFullscreen && !multimediaWatched[position.current]) {
          handleTrackToSend();
        }
        break;
      }

      default:
        break;
    }
  };

  return {
    handleCurrentClipsFirstCase,
    handlePreviousClips,
    handleCurrentClipsSecondCase,
  };
};

export default useClipsHandlers;
