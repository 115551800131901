import providers from './strategy-provider';

const getVideoProvider = provider => providers[provider].component;

const play = (player, provider) => {
  const method = providers[provider].play;

  return method(player);
};

const pause = (player, provider) => {
  const method = providers[provider].pause;

  return method(player);
};

const mute = (player, provider) => {
  const method = providers[provider].mute;

  return method(player);
};

const unmute = (player, provider) => {
  const method = providers[provider].unmute;

  return method(player);
};

const suscribeEvent = (player, provider, event, action) => {
  const method = providers[provider].suscribe;

  return method(player, event, action);
};

const unsuscribeEvent = (player, provider, event) => {
  const method = providers[provider].unsuscribe;

  return method(player, event);
};

const setSrc = (player, source, provider) => {
  const method = providers[provider].src;

  return method(player, source);
};

const reset = (player, provider, callback) => {
  const method = providers[provider].reset;

  return method(player, callback);
};

const restart = (player, provider) => {
  const method = providers[provider].restart;

  return method(player);
};

const clickVideo = (player, provider) => {
  const method = providers[provider].clickVideo;

  return method(player);
};

const tapVideo = (player, provider) => {
  const method = providers[provider].tapVideo;

  return method(player);
};

const removeRestrictions = (player, provider) => {
  const method = providers[provider].removeRestrictions;

  return method(player);
};

const isPlaying = (player, provider) => {
  const method = providers[provider].isPlaying;

  return method(player);
};

const isMuted = (player, provider) => {
  const method = providers[provider].isMuted;

  return method(player);
};

export {
  clickVideo,
  getVideoProvider,
  isMuted,
  isPlaying,
  mute,
  pause,
  play,
  removeRestrictions,
  reset,
  restart,
  setSrc,
  suscribeEvent,
  tapVideo,
  unmute,
  unsuscribeEvent,
};
