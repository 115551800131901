import React, { useState, useRef, useImperativeHandle, useContext } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { constants } from '../../../../utils/constants';
import { CLIP_VIDEO_ID } from '../../../clip-video/constants';
import { initMouseHoverConfig } from '../../../clip-multimedia/utils/multimedia-events';
import ClipVideo from '../../../clip-video';
import { useClipsEffects } from '../../hooks/use-clips-effects';
import getClipMethods from '../../events/get-clip-methods';
import getClickHandler from '../../events/get-click-handler';
import GalleryPropsContext from '../../context/gallery.contex';

const { DEVICE_TYPE } = constants;

const ClipsWorkerComponent = React.forwardRef(({ clipsConfig, showSnackbar, figures }, ref) => {
  const { position, snap, isCurrentClips, isPreviousClips, isFullscreen } = useContext(GalleryPropsContext);
  const [multimediaWatched, setMultimediaWatched] = useState(() => figures.map(() => false));
  const playerRef = useRef(null);
  const videoClipElRef = useRef(null);
  const isFullscreenRef = useRef();
  const mouseHoverConfigRef = useRef({ ...initMouseHoverConfig });
  const eventHandlersRef = useRef({});

  const spinnerConfig = useRef({
    delay: 3000,
    delayId: undefined,
  });

  const multimediaClipRef = useRef({
    hasStarted: false,
    loading: undefined,
    muted: clipsConfig?.autoplay,
    paused: true,
    timeToLeave: 0,
    error: false,
  });

  const {
    handleTouch,
    onSrcChanged,
    onWaiting,
    sendIntentionToViewTrack,
    onPlaying,
    onPause,
    onVolumeChange,
    onTimeUpdate,
    onError,
    onMute,
    onClickVideo,
  } = getClipMethods({
    deviceType: DEVICE_TYPE.DESKTOP,
    multimediaClipRef,
    playerRef,
    isFullscreenRef,
    mouseHoverConfigRef,
    spinnerConfig,
    clipsConfig,
    isCurrentClips,
    showSnackbar,
    multimediaWatched,
    setMultimediaWatched,
    position,
    snap,
  });

  const clipHandler = getClickHandler({ multimediaClipRef, onClickVideo });

  useClipsEffects({
    deviceType: DEVICE_TYPE.DESKTOP,
    clipsConfig,
    videoClipElRef,
    multimediaClipRef,
    isFullscreenRef,
    playerRef,
    snap,
    isCurrentClips,
    isFullscreen,
    onPause,
    isPreviousClips,
    position,
    mouseHoverConfigRef,
    eventHandlersRef,
    sendIntentionToViewTrack,
  });

  useImperativeHandle(
    ref,
    () => ({
      clipHandler,
      handleTouch,
      onMute,
    }),
    [clipHandler, handleTouch, onMute],
  );

  return (
    <ClipVideo
      ref={playerRef}
      id={CLIP_VIDEO_ID}
      currentVideo={snap.currentClip}
      autoplay={clipsConfig?.autoplay}
      deviceType={DEVICE_TYPE.MOBILE}
      onError={onError}
      onPause={onPause}
      onPlaying={onPlaying}
      onSrcChanged={onSrcChanged}
      onTimeUpdate={onTimeUpdate}
      onVolumeChange={onVolumeChange}
      onWaiting={onWaiting}
      withEvents={isCurrentClips}
      hide
    />
  );
});

ClipsWorkerComponent.propTypes = {
  clipsConfig: shape({}),
  showSnackbar: func,
  figures: arrayOf(shape({})),
};

export { ClipsWorkerComponent };
