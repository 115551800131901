import { ID_CONTAINER } from '../constants';

const getMultimediaProps = (
  videoId,
  multimedia,
  isFullscreen,
  mouseHoverConfig = null,
  isBottomMultimedia = false,
) => ({
  multimedia,
  videoContainer: {
    current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
    opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
  },
  videoId,
  isBottomMultimedia,
  mouseHoverConfig,
});

export default getMultimediaProps;
